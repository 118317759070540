import { UserOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Avatar, Layout, Menu, Space, Typography } from "antd";
import Flags from "country-flag-icons/react/3x2";
import { Fragment, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { authenticationService } from "../../common/auth";
import { formatCurrency } from "../../common/formatter";
import { useQuery } from "../../hooks";
import { Crisp } from "../Crisp";
import styles from "./styles.module.less";
import * as g from "./__generated__/HeaderUser";

const GET_USER = gql`
  query HeaderUser {
    user {
      id
      login
      email
      balance
    }
  }
`;

const flags: { [index: string]: React.ReactNode } = {
  "en": <Flags.US className={styles.flag} title="English" />,
  "ru": <Flags.RU className={styles.flag} title="Русский" />,
};

const HeaderContainer = (props: { children: ReactNode }) => {
  return (
    <Layout.Header className={styles.header}>
      {props.children}
    </Layout.Header >
  );
};

export const Header = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation("header");
  const { data } = useQuery<g.HeaderUser, {}>(GET_USER);

  const balanceMenu = (
    <Space>
      <Typography.Text className={styles.balance} strong={true}>
        {formatCurrency((data?.user.balance || 0) / 100)}
      </Typography.Text>
    </Space>
  );

  const languageMenu = (
    <Menu
      key="language"
      mode="horizontal"
      selectable={false}
      disabledOverflow={true}
      onClick={(v) => i18n.changeLanguage(v.key as string)}
    >
      <Menu.SubMenu key="language-select" title={flags[i18n.language]} >
        <Menu.Item key="en" icon={flags["en"]}>English</Menu.Item>
        <Menu.Item key="ru" icon={flags["ru"]}>Русский</Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  const userMenu = (
    <Menu
      key="user"
      mode="horizontal"
      disabledOverflow={true}
      onClick={() => {
        authenticationService.logout();
        history.push("/login");
      }}
    >
      <Menu.SubMenu
        key="user-select"
        title={
          <Fragment>
            <span>{authenticationService.currentUserValue?.login}</span>
            <Avatar className={styles.avatar} icon={<UserOutlined />} />
          </Fragment>
        }
      >
        <Menu.Item key="signOut">
          {t("Sign Out")}
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <HeaderContainer>
      {balanceMenu}
      {languageMenu}
      {userMenu}
      <Crisp
        safeMode={true}
        configuration={{}}
        attributes={{
          "user:email": [data?.user.email],
          "user:nickname": [data?.user.login],
        }}
      />
    </HeaderContainer>
  );
};
