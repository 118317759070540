import { gql } from "@apollo/client";
import { Button, Card, Select, Space, Spin } from "antd";
import { Fragment, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "../../hooks";
import * as g from "./__generated__/PortSignature";
import * as gs from "./__generated__/SearchPortSignature";
import * as gu from "./__generated__/UpdatePortSignature";
import * as gt from "./__generated__/UpdatePortTags";

const GET_PORT_SIGNATURE = gql`
  query PortSignature($id: ID!) {
    port(id: $id) {
      id
      tags
      signature {
        id
        raw
      }
    }
  }
`;

const SEARCH_SIGNATURE = gql`
  query SearchPortSignature($input: SearchInput!) {
    searchSignature(input: $input) {
      data {
        id
        raw
      }
    }
  }
`;

const UPDATE_PORT_SIGNATURE = gql`
  mutation UpdatePortSignature($id: ID!, $signature: String!) {
    updatePortSignature(id: $id, signature: $signature) {
      id
      signature {
        id
        raw
      }
    }
  }
`;

const UPDATE_TAGS = gql`
  mutation UpdatePortTags($id: ID!, $tags: [String!]!) {
    updatePortTags(id: $id, tags: $tags) {
      id
      tags
    }
  }
`;

interface IPortSignatureState {
  value: string;
  filter: string
}

const initialState: IPortSignatureState = {
  value: "Linux",
  filter: "",
};

const portSignatureReducer = (
  state: IPortSignatureState,
  action: { type: string, data: any },
) => {
  switch (action.type) {
    case "INIT":
      return { ...state, value: action.data.signature.id };
    case "SEARCH":
      return { ...state, filter: action.data };
    case "SELECT":
      return { ...state, value: action.data };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const EditSignature = () => {
  const { t } = useTranslation("port");
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(portSignatureReducer, initialState);
  const [tags, setTags] = useState([] as string[]);

  const { loading } = useQuery<g.PortSignature, g.PortSignatureVariables>(GET_PORT_SIGNATURE, {
    variables: { id },
    onCompleted: (data) => {
      setTags(data.port.tags);
      dispatch({ type: "INIT", data: data.port });
    }
  });

  const { "data": signatureData } = useQuery<gs.SearchPortSignature, gs.SearchPortSignatureVariables>(SEARCH_SIGNATURE, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        filter: state.filter,
        page: 1,
        pageSize: 0,
      }
    }
  });

  const [updateSignature] = useMutation<gu.UpdatePortSignature, gu.UpdatePortSignatureVariables>(UPDATE_PORT_SIGNATURE, {
    okText: t("Port updated"),
    refetchQueries: ["SearchLogPort"]
  });


  const [updateTags] = useMutation<gt.UpdatePortTags, gt.UpdatePortTagsVariables>(UPDATE_TAGS, {
    okText: t("Port updated"),
    refetchQueries: ["UpdatePortTags"],
  });


  if (loading) {
    return <Spin />;
  }


  return (
    <Fragment>
      <Card title={t("Signature")}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Select
            value={state.value}
            placeholder={t("Select signature")}
            filterOption={false}
            showSearch={true}
            onSearch={(val) => dispatch({ type: "SEARCH", data: val })}
            onChange={(val) => dispatch({ type: "SELECT", data: val })}
            style={{ width: "100%" }}
          >
            {signatureData?.searchSignature.data.map(d =>
              <Select.Option key={d.id} value={d.id}>{d.id}</Select.Option>
            )}
          </Select>

          <Button
            type="primary"
            style={{ margin: "8px 0" }}
            onClick={() => updateSignature({ variables: { id, signature: state.value } })}
          >
            {t("Update")}
          </Button>
        </Space>
      </Card>
      <br />
      <Card title={t("Tags")}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Select
            style={{ width: "100%" }}
            mode="tags"
            placeholder={t("Tags")}
            value={tags}
            onChange={(val) => setTags(val as string[])}
          />

          <Button
            type="primary"
            style={{ margin: "8px 0" }}
            onClick={() => updateTags({ variables: { id, tags } })}
          >
            {t("Update")}
          </Button>
        </Space>
      </Card>
    </Fragment>
  );
};
