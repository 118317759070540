import { Redirect, Router, Switch } from "react-router-dom";
import { history } from "../common/history";
import { Account } from "../components/Account";
import { Billing } from "../components/Billing";
import { Help } from "../components/Help";
import { Login } from "../components/Login";
import { Create as PortCreate, Edit as PortEdit, List as PortList } from "../components/Port";
import { PrivateRoute } from "../components/PrivateRoute";
import { PublicRoute } from "../components/PublicRoute";
import { Activate, Pending, Register } from "../components/Register";

export const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact={true} path="/ports" component={PortList} />
        <PrivateRoute exact={true} path="/ports/new" component={PortCreate} />
        <PrivateRoute path="/ports/:id" component={PortEdit} />

        <PrivateRoute exact={true} path="/billing" component={Billing} />

        <PrivateRoute exact={true} path="/account" component={Account} />

        <PrivateRoute exact={true} path="/help" component={Help} />

        <PublicRoute exact={true} path="/login" component={Login} />
        <PublicRoute exact={true} path="/register" component={Register} />
        <PublicRoute exact={true} path="/register/pending" component={Pending} />
        <PublicRoute exact={true} path="/activate/:token" component={Activate} />

        <Redirect to="/ports" />
      </Switch>
    </Router>
  );
};
