import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { SelectPlan } from "./";

export const Create = () => {
  const { t } = useTranslation("port");

  return (
    <Card
      title={t("Create")}
      bordered={false}
      headStyle={{ padding: "0" }}
      bodyStyle={{ padding: "24px 0" }}
    >
      <SelectPlan />
    </Card>
  );
};
