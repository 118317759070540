import { gql } from "@apollo/client";
import { Space, Spin, Typography } from "antd";
import { useQuery } from "../../hooks";
import { Flag } from "../Flag";
import * as gq from "./__generated__/IP";

const PORT_IP_QUERY = gql`
  query IP($id: ID!) {
    port(id: $id) {
      id
      ip
      geoip {
        countryCode2
      }
    }
  }
`;

interface IPProps {
  id: string;
}

export const IP = (props: IPProps) => {
  const { loading, data } = useQuery<gq.IP, gq.IPVariables>(PORT_IP_QUERY, {
    variables: { id: props.id }
  });

  if (loading || !data) {
    return <Spin />;
  }

  return (
    <Space>
      <Flag code={data.port.geoip.countryCode2} size={24} />
      <Typography.Text>{data.port.ip}</Typography.Text>
    </Space >
  );
};
