import { useEffect, useRef } from "react";

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const pushCrisp = (method: any, parameters: any) => {
  if (Object.keys(parameters).length > 0) {
    const items = Object.entries(parameters);

    items.map((item) => {
      const [key, value] = item;
      return (global as any).$crisp.push([method, key, value]);
    });
  }
};

export const Crisp = (props: any) => {
  const {
    crispTokenId,
    crispRuntimeConfig,
    safeMode,
    configuration,
    attributes,
  } = props;

  const previousCrispTokenId = usePrevious(crispTokenId);
  if (previousCrispTokenId && previousCrispTokenId !== crispTokenId) {
    throw Error("crispTokenId can't be changed");
  }

  const previousCrispRuntimeConfig = usePrevious(crispRuntimeConfig);
  if (previousCrispRuntimeConfig && previousCrispRuntimeConfig !== crispRuntimeConfig) {
    throw Error("crispRuntimeConfig can't be changed");
  }

  const previousSafeMode = usePrevious(safeMode);
  if (previousSafeMode && previousSafeMode !== safeMode) {
    throw Error("safeMode can't be changed");
  }

  if ((global as any).$crisp === undefined) {
    (global as any).$crisp = [["safe", safeMode]];
  }

  pushCrisp("set", attributes);
  pushCrisp("config", configuration);

  const scriptUrl = "https://client.crisp.chat/l.js";
  const scripts = document.querySelector(`script[src='${scriptUrl}']`);
  if (scripts === null) {
    (global as any).CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID || "";
    (global as any).CRISP_TOKEN_ID = crispTokenId;
    (global as any).CRISP_RUNTIME_CONFIG = crispRuntimeConfig;

    const script = document.createElement("script");
    script.src = scriptUrl;
    script.async = true;
    document.head.appendChild(script);
  }

  return <></>;
};
