import { gql, useMutation } from "@apollo/client";
import { Button, Form, notification, Select, Space, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../hooks";
import { Flag } from "../Flag";
import * as gu from "./__generated__/UpdateUserPreferences";
import * as g from "./__generated__/UserPreferences";

const GET_PREFERENCES = gql`
  query UserPreferences {
    user {
      id
      preferences {
        vpnServer
        proxyServer
        proxyProtocol
      }
    }
  }
`;

const UPDATE_PREFERENCES = gql`
  mutation UpdateUserPreferences($input: UpdateUserPreferencesInput!) {
    updateUserPreferences(input: $input) {
      id
      preferences {
        vpnServer
        proxyServer
        proxyProtocol
      }
    }
  }
`;

const formItemLayout = {
  labelCol: { span: 4, offset: 0 },
  wrapperCol: { span: 6, offset: 0 },
};

const tailFormItemLayout = {
  wrapperCol: { span: 6, offset: window.innerWidth > 576 ? 4 : 0 },
};

export const Preferences = () => {
  const { t } = useTranslation("account");
  const [form] = Form.useForm();
  const { loading, data } = useQuery<g.UserPreferences, {}>(GET_PREFERENCES);

  const [updatePreferences] = useMutation<gu.UpdateUserPreferences, gu.UpdateUserPreferencesVariables>(UPDATE_PREFERENCES, {
    onError: (err) => notification.error({ message: err.message }),
    onCompleted: (_) => notification.success({ message: t("Preferences updated") }),
  });


  if (loading) {
    return <Spin />;
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Typography.Title level={3}>{t("User preferences")}</Typography.Title>

      <Form
        {...formItemLayout}
        form={form}
        name="preferences"
        onFinish={(values) => updatePreferences({ variables: { input: values } })}
        initialValues={data?.user.preferences}
        scrollToFirstError={true}
      >
        <Form.Item name="proxyProtocol" label={t("Proxy protocol")}>
          <Select style={{ width: 120 }}>
            <Select.Option value="http">http</Select.Option>
            <Select.Option value="https">https</Select.Option>
            <Select.Option value="socks5">socks5</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name="proxyServer" label={t("Proxy server")}>
          <Select style={{ width: 240 }}>
            <Select.Option value="ap1.ltesocks.io">
              <Space>
                <Flag code="GB" size={24} />
                ap1.ltesocks.io
              </Space>
            </Select.Option>
            <Select.Option value="ap2.ltesocks.io">
              <Space>
                <Flag code="US" size={24} />
                ap2.ltesocks.io
              </Space>
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name="vpnServer" label={t("VPN server")}>
          <Select style={{ width: 240 }}>
            <Select.Option value="vpn1.ltesocks.io">
              <Space>
                <Flag code="NL" size={24} />
                vpn1.ltesocks.io
              </Space>
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t("Update")}
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
};
