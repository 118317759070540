import { gql, useMutation } from "@apollo/client";
import { Button, Form, Input, notification, Space, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../hooks";
import * as gu from "./__generated__/UpdateProfile";
import * as g from "./__generated__/UserProfile";

const GET_PROFILE = gql`
  query UserProfile {
    user {
      id
      login
      email
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($password: String!, $input: UpdateUserInput!) {
    updateUser(password: $password, input: $input) {
      id
      email
    }
  }
`;


const formItemLayout = {
  labelCol: { span: 4, offset: 0 },
  wrapperCol: { span: 6, offset: 0 },
};

const tailFormItemLayout = {
  wrapperCol: { span: 6, offset: window.innerWidth > 576 ? 4 : 0 },
};

export const Profile = () => {
  const { t } = useTranslation("account");
  const [form] = Form.useForm();
  const { loading, data } = useQuery<g.UserProfile, {}>(GET_PROFILE);

  const [updateUser] = useMutation<gu.UpdateProfile, gu.UpdateProfileVariables>(UPDATE_PROFILE, {
    onError: (err) => notification.error({ message: err.message }),
    onCompleted: (_) => notification.success({ message: t("Profile updated") }),
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Typography.Title level={3}>{t("Update profile")}</Typography.Title>

      <Form
        {...formItemLayout}
        form={form}
        name="profile"
        onFinish={(values) => updateUser({ variables: { password: values.password, input: values } })}
        initialValues={data?.user}
        scrollToFirstError={true}
      >
        <Form.Item
          name="email"
          label={t("E-Mail")}
          rules={[
            { type: "email" },
            { required: true },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label={t("Current password")}
          rules={[
            { required: true },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label={t("New password")}
          extra={t("Leave empty to preserve current password")}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailFormItemLayout} >
          <Button type="primary" htmlType="submit">
            {t("Update")}
          </Button>
        </Form.Item>
      </Form>
    </Space >
  );
};
