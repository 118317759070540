import { LeftCircleTwoTone, RightCircleTwoTone } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Alert } from "antd";
import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "../hooks";
import * as g from "./__generated__/Banners";

const POLL_INTERVAL = 10 * 60 * 1000;
const PAUSE_INTERVAL = 2 * 60 * 1000;
const ROTATION_INTERVAL = 30 * 1000;

const GET_BANNERS = gql`
  query Banners($lang: BannerLanguage!) {
    banners(lang: $lang)
  }
`;

interface BannerState {
  banners: string[];
  currentIndex: number;
  currentBanner: string;
  pauseRotation: boolean;
}

const initialState: BannerState = {
  banners: [],
  currentIndex: 0,
  currentBanner: "",
  pauseRotation: false,
};

type BannerAction =
  | { type: "INIT", banners: string[] }
  | { type: "PREV" }
  | { type: "NEXT" }
  | { type: "ROTATE" };

const bannerReducer = (
  state: BannerState,
  action: BannerAction
) => {
  switch (action.type) {
    case "INIT":
      const currentBanner = action.banners.length > 0 ? action.banners[0] : "";
      return { ...initialState, currentBanner, banners: action.banners };
    case "PREV":
      var prevIndex = state.currentIndex - 1;
      if (prevIndex < 0) {
        prevIndex = state.banners.length - 1;
      }

      return {
        ...state,
        currentIndex: prevIndex,
        currentBanner: state.banners[prevIndex],
        pauseRotation: true,
      };
    case "NEXT":
    case "ROTATE":
      var nextIndex = state.currentIndex + 1;
      if (nextIndex >= state.banners.length) {
        nextIndex = 0;
      }

      return {
        ...state,
        currentIndex: nextIndex,
        currentBanner: state.banners[nextIndex],
        pauseRotation: action.type === "NEXT",
      };
    default:
      return { ...state };
  }
};

const BannerHTML = (props: { html: string }) => {
  return (
    <div style={{ height: "180px", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: props.html }} />
  );
};

export const Banner = () => {
  const { i18n } = useTranslation();
  const [state, dispatch] = useReducer(bannerReducer, initialState);

  useQuery<g.Banners, {}>(GET_BANNERS, {
    variables: { lang: i18n.language },
    pollInterval: POLL_INTERVAL,
    onCompleted: (data) => {
      dispatch({ type: "INIT", banners: data.banners });
    }
  });

  useEffect(() => {
    if (state.banners.length < 2) {
      return;
    }

    const id = setTimeout(() => {
      dispatch({ type: "ROTATE" });
    }, state.pauseRotation ? PAUSE_INTERVAL : ROTATION_INTERVAL);

    return () => clearTimeout(id);
  }, [state]);

  const controls = (
    <div style={{ marginLeft: 0 }}>
      <LeftCircleTwoTone style={{ marginRight: 3 }} onClick={() => dispatch({ type: "PREV" })} />
      <RightCircleTwoTone onClick={() => dispatch({ type: "NEXT" })} />
    </div>
  );

  return (
    <Alert
      type="info"
      style={{ alignItems: "flex-start" }}
      action={state.banners.length > 1 && controls}
      message={<BannerHTML html={state.currentBanner} />}
    />
  );
};

