import { ApolloProvider } from "@apollo/client";
import { ConfigProvider, Spin } from "antd";
import "antd/dist/antd.css";
import { Locale } from "antd/es/locale-provider";
import enUS from "antd/es/locale/en_US";
import ruRU from "antd/es/locale/ru_RU";
import { ConfigProviderProps } from "antd/lib/config-provider";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { client } from "./common/client";
import "./common/i18n";
import { App } from "./containers/App";

dayjs.extend(LocalizedFormat);

const antdLocales: { [index: string]: Locale } = {
  "en": enUS,
  "ru": ruRU,
};

const ConfigProviderWithLocale = (props: Omit<ConfigProviderProps, "locale">) => {
  const { i18n } = useTranslation();
  dayjs.locale(i18n.language);

  return (
    <ConfigProvider locale={antdLocales[i18n.language]}>
      {props.children}
    </ConfigProvider >
  );
};

ReactDOM.render(
  <Suspense fallback={<Spin size="large" />}>
    <ApolloProvider client={client}>
      <ConfigProviderWithLocale>
        <App />
      </ConfigProviderWithLocale>
    </ApolloProvider>
  </Suspense>,
  document.getElementById("app")
);
