import { Col, Row, Tabs, Typography } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { API, Limits, Preferences, Profile } from "./";

export const Account = () => {
  const { t } = useTranslation("account");

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Typography.Title level={2}>{t("Account")}</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="profile">
            <Tabs.TabPane tab={t("Profile")} key="profile">
              <Profile />
            </Tabs.TabPane>

            <Tabs.TabPane tab={t("Preferences")} key="preferences">
              <Preferences />
            </Tabs.TabPane>

            <Tabs.TabPane tab={t("Limits")} key="limits">
              <Limits />
            </Tabs.TabPane>

            <Tabs.TabPane tab={t("API")} key="api">
              <API />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Fragment>
  );
};
