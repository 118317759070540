import { hasFlag } from "country-flag-icons";
import Flags from "country-flag-icons/react/3x2";

interface FlagProps {
  code: string;
  size: 16 | 24 | 32 | 48 | 64;
}

export const Flag = (props: FlagProps) => {
  if (!hasFlag(props.code.toUpperCase())) {
    return <Flags.AQ style={{ width: `${props.size}px` }} />;
  }

  const Component = Flags[props.code.toUpperCase()];
  return <Component style={{ width: `${props.size}px` }} />;
};
