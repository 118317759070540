import { CopyOutlined, RetweetOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Input, notification, Popconfirm, Space, Spin, Typography } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../hooks";
import * as g from "./__generated__/APIToken";
import * as gu from "./__generated__/UpdateUserAPIToken";

const GET_TOKEN = gql`
  query APIToken {
    user {
      id
      apiToken
    }
  }
`;

const REFRESH_TOKEN = gql`
  mutation UpdateUserAPIToken {
    updateUserAPIToken {
      id
      apiToken
    }
  }
`;

export const API = () => {
  const { t } = useTranslation("account");
  const { loading, data } = useQuery<g.APIToken, {}>(GET_TOKEN);

  const [refreshToken] = useMutation<gu.UpdateUserAPIToken, {}>(REFRESH_TOKEN, {
    onError: (err) => notification.error({ message: err.message }),
    onCompleted: (_) => notification.success({ message: t("Token updated") }),
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Space direction="vertical">
      <Typography.Title level={3}>{t("API Token")}</Typography.Title>

      <Typography.Paragraph>
        {t("API Integration")}
      </Typography.Paragraph>

      <Input
        disabled={true}
        value={data?.user.apiToken}
        addonAfter={
          <Popconfirm
            title={t("Refresh API Token?")}
            okText={t("Yes")}
            cancelText={t("No")}
            onConfirm={() => refreshToken()}
          >
            <RetweetOutlined />
          </Popconfirm>
        }
        addonBefore={
          <CopyToClipboard
            text={data?.user.apiToken || ""}
            onCopy={() => notification.info({ message: t("Token copied") })}
          >
            <CopyOutlined />
          </CopyToClipboard>
        }
      />

      <Typography.Paragraph>
        <a href="https://api.ltesocks.io/docs" target="_blank" rel="noopener noreferrer">{t("Documentation")} v1</a>
        <br />
        <a href="https://api.ltesocks.io/docs/redoc.html" target="_blank" rel="noopener noreferrer">{t("Documentation")} v2</a>
      </Typography.Paragraph>
    </Space>
  );
};
