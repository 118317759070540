import { gql } from "@apollo/client";
import { Card, Col, Form, Row, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { Fragment, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { formatDate } from "../../common/formatter";
import { useQuery } from "../../hooks";
import DatePicker from "../DatePicker";
import * as g from "./__generated__/SearchLogPort";

const { RangePicker } = DatePicker;

const GET_PORT_LOG = gql`
  query SearchLogPort($id: ID!, $input: SearchLogInput!) {
    searchLogPort(id: $id, input: $input) {
      data {
        id
        ip
        action
        cause
        source
        extra
        createdAt
      }
      page
      pageSize
      total
    }
  }
`;

interface LogState {
  from: Date,
  to: Date,
  page: number
  pageSize: number
}

const initialState: LogState = {
  from: dayjs().startOf("day").toDate(),
  to: dayjs().endOf("day").toDate(),
  page: 1,
  pageSize: 20,
};

const logReducer = (
  state: LogState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    case "PAGINATE":
      return { ...state, page: action.data.page, pageSize: action.data.pageSize };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

const renderSource = (text: string) => {
  switch (text) {
    case "userApi":
      return <Tag color="green">api</Tag>;
    case "userDashboard":
      return <Tag color="cyan">dashboard</Tag>;
    case "adminDashboard":
      return <Tag color="blue">admin</Tag>;
    default:
      return <Tag color="lime">system</Tag>;
  }
};

const columns = (t: TFunction): ColumnsType<g.SearchLogPort_searchLogPort_data> => [
  {
    key: "action",
    title: t("Action"),
    render: (record) => record.action
  },
  {
    key: "ip",
    title: t("IP"),
    render: (record) => record.ip
  },
  {
    key: "cause",
    title: t("Cause"),
    render: (record) => record.cause
  },
  {
    key: "source",
    title: t("Source"),
    render: (record) => renderSource(record.source)
  },
  {
    key: "extra",
    title: t("Extra"),
    render: (record) => record.extra
  },
  {
    key: "createdAt",
    title: t("Created At"),
    render: (record) => formatDate(record.createdAt)
  },
];

export const EditLog = () => {
  const { t } = useTranslation("port");
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(logReducer, initialState);

  const { loading, data } = useQuery<g.SearchLogPort, g.SearchLogPortVariables>(GET_PORT_LOG, {
    variables: {
      id: id,
      input: state,
    }
  });

  return (
    <Card title={t("Log")}>
      <Fragment>
        <Form name="control">
          <Row gutter={24}>
            <Col span={12}>
              <RangePicker
                allowClear={false}
                showTime={true}
                value={[dayjs(state.from), dayjs(state.to)]}
                onChange={(dates) => dispatch({ type: "DATES", data: dates })}
              />
            </Col>
          </Row>
        </Form>
        <br />
        <Table
          size="middle"
          rowKey={record => record.id}
          loading={loading}
          columns={columns(t)}
          dataSource={data?.searchLogPort.data}
          pagination={{
            showSizeChanger: true,
            current: state.page,
            pageSize: state.pageSize,
            total: data?.searchLogPort.total,
          }}
          onChange={(pagination) => dispatch({ type: "PAGINATE", data: { page: pagination.current, pageSize: pagination.pageSize } })}
        />
      </Fragment>
    </Card>
  );
};
