import { AppleOutlined, CopyOutlined, DeleteOutlined, DesktopOutlined, GlobalOutlined, PlusOutlined, QuestionCircleFilled, TeamOutlined, WindowsOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Button, Divider, notification, Popconfirm, Popover, Space, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import { t, TFunction } from "i18next";
import { Fragment } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { formatDate } from "../../common/formatter";
import { ListContainer } from "../../containers/ListContainer";
import { useMutation, useQuery, useSearch } from "../../hooks";
import useWidth from "../../hooks/useWidth";
import * as gt from "../../__generated__/globalTypes";
import { IP, Status } from "./";
import styles from "./styles.module.less";
import * as gd from "./__generated__/DeletePort";
import * as gc from "./__generated__/PortsLimit";
import * as gr from "./__generated__/ResetPort";
import * as g from "./__generated__/SearchPort";


const GET_PORTS = gql`
  query SearchPort($input: SearchInput!) {
    user {
      id
      portsLimit
      preferences {
        vpnServer
        proxyServer
        proxyProtocol
      }
    }
    searchPort(input: $input) {
      data {
        id
        status
        resetToken
        signature {
          id
          raw
        }
        credentials {
          kind
          login
          ipFilter
        }
        plan {
          enabled
          name
          autoRenew
          tarification {
            time
            traffic
            price
          }
          activatedAt
          expiresAt
          trafficRemains
        }
        updatedAt
      }
      page
      pageSize
      total
    }
  }
`;

const CHECK_PORTS_LIMIT = gql`
  query PortsLimit {
    user {
      id
      portsCount
      portsLimit
    }
  }
`;

const DELETE_PORT = gql`
  mutation DeletePort($id: ID!) {
    deletePort(id: $id)
  }
`;

const RESET_PORT = gql`
  mutation ResetPort($id: ID!) {
    resetPort(id: $id) {
      id
    }
  }
`;

const renderURL = (width: number, protocol?: string, ap?: string) => (record: g.SearchPort_searchPort_data) => {
  return (width < 1025 ?
    <Space>
      <span>Click to copy</span>
      <CopyToClipboard
        text={`${protocol}://${ap}:${record.id}`}
        onCopy={() => notification.info({ message: t("Link copied") })}>
        <CopyOutlined />
      </CopyToClipboard>
    </Space> : <Typography.Text copyable={true}>{`${protocol}://${ap}:${record.id}`}</Typography.Text>);

};

const renderCredentials = (t: TFunction, record: g.SearchPort_searchPort_data) => {
  const ipCreds = record.credentials.filter(c => c.kind === gt.PortCredentialsKind.ip);
  const passwordCreds = record.credentials.filter(c => c.kind === gt.PortCredentialsKind.password);

  const details = (
    <Fragment>
      <Divider>{t("IP")} ({ipCreds.length})</Divider>
      <Space direction="vertical">
        {ipCreds.map(c => <Typography.Text key={c.ipFilter}>{c.ipFilter}</Typography.Text>)}
      </Space>
      <Divider>{t("Password")} ({passwordCreds.length})</Divider>
      <Space direction="vertical">
        {passwordCreds.map((c, i) => <Typography.Text key={`${c.login}-${i}`}>{c.login}</Typography.Text>)}
      </Space>
      <Divider>
        <Link to={`/ports/${record.id}`}>
          <Button type="primary">{t("Edit")}</Button>
        </Link>
      </Divider>
    </Fragment>
  );

  return (
    <Space>
      <span><Tooltip title={t("IP")}><GlobalOutlined /></Tooltip> {ipCreds.length}</span>
      <span><Tooltip title={t("Password")}><TeamOutlined /></Tooltip> {passwordCreds.length}</span>
      <Popover content={details} title={t("Credentials")}>
        <QuestionCircleFilled className={styles.popoverMark} />
      </Popover>
    </Space>
  );
};

const renderSignature = (record: g.SearchPort_searchPort_data) => {
  switch (record.signature.id) {
    case "Windows":
      return <Typography.Text><WindowsOutlined /> {record.signature.id}</Typography.Text>;
    case "MacOS X":
      return <Typography.Text><AppleOutlined /> {record.signature.id}</Typography.Text>;
    default:
      return <Typography.Text><DesktopOutlined /> {record.signature.id}</Typography.Text>;
  }
};

const renderPlan = (_: any, record: g.SearchPort_searchPort_data) => {
  if (!record.plan.enabled) {
    return "N/A";
  }

  return `${record.plan.name} (${record.plan.tarification.time / 86400} Days)`;
};

const renderExpiresAt = (_: any, record: g.SearchPort_searchPort_data) => {
  if (!record.plan.enabled) {
    return "N/A";
  }

  return formatDate(record.plan.expiresAt);
};



export const List = () => {
  const { t } = useTranslation("port");
  const history = useHistory();
  const { result, search, updateFilter, updateTable } = useSearch<g.SearchPort>(GET_PORTS);
  const width = useWidth();

  const { refetch } = useQuery<gc.PortsLimit, {}>(CHECK_PORTS_LIMIT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [deletePort] = useMutation<gd.DeletePort, gd.DeletePortVariables>(DELETE_PORT, {
    okText: t("Port deleted"),
    refetchQueries: ["SearchPort"],
  });

  const [resetPort] = useMutation<gr.ResetPort, gr.ResetPortVariables>(RESET_PORT, {
    okText: t("Reset scheduled"),
    refetchQueries: ["SearchPort"],
  });



  const columns: ColumnsType<g.SearchPort_searchPort_data> = [
    {
      dataIndex: "id",
      title: t("Port"),
      fixed: "left",
      sorter: true,
      defaultSortOrder: "descend" as SortOrder,
      render: (_, record) => <Status id={record.id} showLink={true} useTooltip={true} />
    },
    {
      key: "ip",
      title: t("IP"),
      render: (record) => <IP id={record.id} />
    },
    {
      key: "reset",
      title: t("Reset (Link)"),
      align: "center",
      render: (record) => <Space>
        <Button onClick={() => resetPort({ variables: { id: record.id } })}>{t("Reset IP")}</Button>
        <CopyToClipboard
          text={`https://api.ltesocks.io/v2/port/reset/${record.resetToken}`}
          onCopy={() => notification.info({ message: t("Link copied") })}
        >
          <CopyOutlined />
        </CopyToClipboard>
      </Space>
    },
    {
      key: "url",
      title: t("URL"),
      render: renderURL(width, result.data?.user.preferences.proxyProtocol, result.data?.user.preferences.proxyServer),
    },
    {
      key: "credentials",
      title: t("Credentials"),
      render: (record) => renderCredentials(t, record),
    },
    {
      key: "signature",
      title: t("Signature"),
      render: renderSignature,
    },
    {
      dataIndex: ["plan", "name"],
      title: t("Plan"),
      sorter: true,
      render: renderPlan,
    },
    {
      dataIndex: ["plan", "expiresAt"],
      title: t("Expires At"),
      sorter: true,
      render: renderExpiresAt,
    },
    {
      dataIndex: "updatedAt",
      title: t("Updated At"),
      sorter: true,
      render: (_, record) => formatDate(record.updatedAt),
    },
    {
      dataIndex: "delete",
      title: t("Delete"),
      render: (_, record) => {
        return (
          <Popconfirm
            title={t("Delete port?")}
            okText={t("Yes")}
            cancelText={t("No")}
            disabled={record.plan.enabled}
            onConfirm={() => deletePort({ variables: { id: record.id } })}
          >
            <Button danger={true} icon={<DeleteOutlined />} disabled={record.plan.enabled} />
          </Popconfirm >
        );
      }
    }
  ];


  return (
    <ListContainer
      create={<Button type="primary" size="large" icon={<PlusOutlined />} onClick={() => refetch().then((result) => {
        if (result.data.user.portsCount >= result.data.user.portsLimit && result.data.user.portsLimit !== 0) {
          notification.error({
            message: t("Error"),
            description: t("ports limit exceeded"),
          });
        } else {
          history.push("/ports/new");
        }
      })}>{t("Create new port")}</Button>}
      search={search}
      columns={columns}
      data={result.data?.searchPort.data}
      total={result.data?.searchPort.total}
      loading={result.loading}
      onSearch={updateFilter}
      onTableChange={updateTable}
    />
  );
};
