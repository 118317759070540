import { gql } from "@apollo/client";
import { Card, Col, Row, Spin } from "antd";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "../../hooks";
import { EditCredentials, EditLog, EditPlan, EditSignature, EditStatus, SelectPlan } from "./";
import * as gq from "./__generated__/PortEditStatus";

const GET_PORT_STATUS = gql`
  query PortEditStatus($id: ID!) {
    port(id: $id) {
      id
      status
      plan {
        enabled
        name
        autoRenew
        tarification {
          time
          traffic
          price
        }
        activatedAt
        expiresAt
        trafficRemains
      }
    }
  }
`;

export const Edit = () => {
  const { id } = useParams<{ id: string }>();

  const { loading, data } = useQuery<gq.PortEditStatus, gq.PortEditStatusVariables>(GET_PORT_STATUS, {
    variables: { id }
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Card
      title={id}
      bordered={false}
      headStyle={{ padding: "0" }}
      bodyStyle={{ padding: "24px 0" }}
    >
      {data!.port.status === "suspended" && <SelectPlan />}
      {data!.port.status !== "suspended" && <Fragment>
        {data!.port.plan.enabled && <Row gutter={24}>
          <Col span={24}><EditPlan /></Col>
        </Row>}
        {data!.port.plan.enabled && <br />}
        <Row gutter={24}>
          <Col span={12}><EditStatus /></Col>
          <Col span={12}><EditSignature /></Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={24}><EditCredentials /></Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={24}><EditLog /></Col>
        </Row>
      </Fragment>}
    </Card >
  );
};
