import { DashboardOutlined } from "@ant-design/icons";
import { Breadcrumb as BC } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { sections } from "../Sections";
import styles from "./styles.module.less";

export const Breadcrumb = () => {
  const { t } = useTranslation("section");
  const location = useLocation();

  const section = location.pathname.split("/")[1];
  const sectionCrumb = sections[section];

  return (
    <BC style={{ margin: "16px 0" }}>
      <BC.Item>
        <Link to="/">
          <DashboardOutlined />
        </Link>
      </BC.Item>
      <BC.Item>
        <Link to={`/${section}`}>
          {sectionCrumb.icon}
          <span className={styles.title}>{t(sectionCrumb.text)}</span>
        </Link>
      </BC.Item>
    </BC>
  );
};
