import { gql } from "@apollo/client";
import { Affix, Badge, Button, Card, Col, Row, Select, Space, Spin, Statistic, Typography } from "antd";
import { TFunction } from "i18next";
import { Fragment, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { formatCurrency } from "../../common/formatter";
import { useMutation, useQuery } from "../../hooks";
import useWidth from "../../hooks/useWidth";
import { Flag } from "../Flag";
import * as gu from "./__generated__/ActivatePlan";
import * as gc from "./__generated__/CreatePort";
import * as g from "./__generated__/SelectPlan";

const SELECT_PLAN = gql`
  query SelectPlan($input: SearchInput!) {
    user {
      id
      discountPercent
    }
    searchPlan(input: $input) {
      data {
        id
        name
        description
        countryCode
        vpnAccess
        available
        tarifications {
          time
          traffic
          price
        }
      }
      page
      pageSize
      total
    }
  }
`;

const ACTIVATE_PLAN = gql`
  mutation ActivatePlan($id: ID!, $input: PortPlanInput!) {
    updatePortPlan(id: $id, input: $input) {
      id
      status
    }
  }
`;

const CREATE_PORT = gql`
  mutation CreatePort($input: PortPlanInput!) {
    createPort(input: $input) {
      id
    }
  }
`;

const formatOptTitle = (opt: any) => {
  if (opt.time === undefined) {
    return undefined;
  }

  const time = opt.time < 86400 ? `${opt.time / 3600} Hours` : `${opt.time / 86400} Days`;
  const traffic = opt.traffic <= 0 ? "∞" : opt.traffic < 1024 ? `${opt.traffic} MB` : `${opt.traffic / 1024} GB`;

  return `${time} / ${traffic} / ${formatCurrency(opt.price / 100)}`;
};


const formatDescription = (t: TFunction, plan: g.SelectPlan_searchPlan_data) => {
  if (!plan.available) {
    return t("Not available, please contant support");
  }

  return plan.description === "" ? t("No description") : plan.description;
};

interface SelectPlanState {
  id: string;
  opt?: g.SelectPlan_searchPlan_data_tarifications;
  plans: g.SelectPlan_searchPlan_data[];
  requestInFlight: boolean;
}

const initialState: SelectPlanState = {
  id: "",
  plans: [],
  requestInFlight: false,
};

const selectPlanReducer = (
  state: SelectPlanState,
  action: { type: string, data: any },
): SelectPlanState => {
  switch (action.type) {
    case "INIT":
      var plan = action.data.find((x: any) => x.available);
      return { ...state, plans: action.data, id: plan.id, opt: plan.tarifications[0] };
    case "SELECT_PLAN":
      var targetPlan = state.plans.find(x => x.id === action.data);
      if (!targetPlan?.available) {
        return { ...state };
      }

      if (state.id === action.data) {
        return { ...state };
      }

      return { ...state, id: action.data, opt: targetPlan?.tarifications[0] };
    case "SELECT_OPT":
      return { ...state, opt: state.plans.find(x => x.id === state.id)!.tarifications[action.data] };
    case "SET_REQUEST_IN_FLIGHT":
      return { ...state, requestInFlight: action.data };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

export const SelectPlan = () => {
  const history = useHistory();
  const { t } = useTranslation("port");
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(selectPlanReducer, initialState);
  const width = useWidth();

  const { loading, data } = useQuery<g.SelectPlan, g.SelectPlanVariables>(SELECT_PLAN, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        page: 1,
        pageSize: 0
      },
    },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.searchPlan.data });
    }
  });
  const [activatePlan] = useMutation<gu.ActivatePlan, gu.ActivatePlanVariables>(ACTIVATE_PLAN, {
    okText: t("Plan activated"),
    refetchQueries: ["HeaderUser"],
    onCompleted: (_ => {
      dispatch({ type: "SET_REQUEST_IN_FLIGHT", data: false });
      history.go(0);
    }),
    onError: (_ => {
      dispatch({ type: "SET_REQUEST_IN_FLIGHT", data: false });
    }),
  });

  const [createPort] = useMutation<gc.CreatePort, gc.CreatePortVariables>(CREATE_PORT, {
    okText: t("Port created"),
    refetchQueries: ["HeaderUser"],
    onCompleted: ((data) => {
      dispatch({ type: "SET_REQUEST_IN_FLIGHT", data: false });
      history.push(`/ports/${data.createPort.id}`);
    }),
    onError: (_ => {
      dispatch({ type: "SET_REQUEST_IN_FLIGHT", data: false });
    }),
  });

  const handleActivation = () => {
    dispatch({ type: "SET_REQUEST_IN_FLIGHT", data: true });

    if (state.opt) {
      if (id) {
        activatePlan({ variables: { id, input: { plan: state.id, tarification: state.opt } } });
      } else {
        createPort({ variables: { input: { plan: state.id, tarification: state.opt } } });
      }
    }
  };

  if (loading) {
    return <Spin />;
  }

  const renderPlan = (plan: g.SelectPlan_searchPlan_data) => {
    const description = (
      <Space direction="vertical">
        {formatDescription(t, plan)}
        <Select
          disabled={state.id !== plan.id}
          placeholder={t("Select option")}
          defaultValue={formatOptTitle(plan.tarifications[0])}
          filterOption={false}
          onChange={(val) => dispatch({ type: "SELECT_OPT", data: val })}
          style={{ width: width > 576 ? "260px" : "100%" }}
        >
          {plan.tarifications.map((d, i) =>
            <Select.Option key={`${plan.id}-${i}`} value={i}>{formatOptTitle(d)}</Select.Option>
          )}
        </Select>
      </Space>
    );

    var card = (
      <Card
        hoverable={plan.available}
        bodyStyle={{ paddingTop: "40px" }}
        onClick={() => dispatch({ type: "SELECT_PLAN", data: plan.id })}
      >
        <Card.Meta
          title={plan.name}
          avatar={<Flag code={plan.countryCode} size={64} />}
          description={description}
        />
      </Card>
    );
    if (state.id === plan.id) {
      card = (
        <Badge.Ribbon text={t("Selected")} color="green">
          {card}
        </Badge.Ribbon>
      );
    }

    return (
      <Col span={width > 897 ? 12 : 24} key={plan.id}>
        {card}
      </Col >
    );
  };
  const price = (state.opt?.price || 0);
  const discount = price * data!.user.discountPercent / 100;
  const summaryPrice = price - discount;
  const sorted_data = data!.searchPlan.data.sort((x, y) => Number(y.available) - Number(x.available));

  return (
    <Fragment>
      <Card
        title={t("Select Plan")}
      >
        <Row gutter={[16, 16]} justify="space-between">
          {sorted_data.map(x => renderPlan(x))}
        </Row>
      </Card >
      <br />
      <Affix offsetBottom={0}>
        <div style={{ paddingTop: "36px", background: "rgba(255,255,255,1.0)", zIndex: 1 }}>
          <Row>
            <Col>
              <Space size={[16, 0]}>
                {discount !== 0 &&
                  <Statistic
                    title={t("Price")}
                    formatter={(_) => <Typography.Title level={3}>{formatCurrency(price / 100)}</Typography.Title>}
                  />
                }

                {discount !== 0 && <Typography.Title level={3}>-</Typography.Title>}
                {discount !== 0 &&
                  <Statistic
                    title={`${t("Discount")} ${data!.user.discountPercent}%`}
                    formatter={(_) => <Typography.Title level={3}>{formatCurrency(discount / 100)}</Typography.Title>}
                  />
                }
                {discount !== 0 && <Typography.Title level={3}>=</Typography.Title>}
                <Statistic
                  title={t("Summary")}
                  formatter={(_) => <Typography.Title level={3}>{formatCurrency(summaryPrice / 100)}</Typography.Title>}
                />
              </Space>
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                style={{ marginLeft: "32px", marginTop: "16px" }}
                loading={state.requestInFlight}
                onClick={() => handleActivation()}
              >
                {t("Activate")}
              </Button>
            </Col>
          </Row>
        </div>
      </Affix>
    </Fragment >
  );
};
