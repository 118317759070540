import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Grid, Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { sections } from "../Sections";
import "./styles.scss";

const { Sider } = Layout;

export const Sidebar = () => {
  const { t } = useTranslation("section");
  const location = useLocation();
  const width = Grid.useBreakpoint();
  const [isCollapsed, setCollapsed] = useState(false);

  useEffect(() => {
    width.md ? setCollapsed(false) : setCollapsed(true);
  }, [width]);

  return (
    <Sider
      breakpoint="lg"
      className="sider"
      collapsible={true}
      collapsed={isCollapsed}
    >
      <Button
        className="trigger"
        type="primary"
        style={{ display: isCollapsed ? "block" : "none" }}
        icon={<MenuOutlined />}
        onClick={() => setCollapsed(!isCollapsed)}
      />

      <Menu key="sidebar" mode="inline" theme="dark" selectedKeys={[location.pathname.split("/")[1]]} >
        <Menu.Item key="en">
          <div className="logo_div">
            <CloseOutlined className="CloseOutlined" onClick={() => setCollapsed(!isCollapsed)} />
            <div className="logo" />
          </div>
        </Menu.Item>
        {Object.keys(sections).map((key) => {
          return (

            <Menu.Item key={key} icon={sections[key].icon} onClick={() => width.md ? setCollapsed(false) : setCollapsed(true)}>
              <Link to={`/${key}`}>{t(sections[key].text)}</Link>
            </Menu.Item>
          );
        })}
      </Menu>

    </Sider>

  );
};
