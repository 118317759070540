import { Typography } from "antd";
import { Trans, useTranslation } from "react-i18next";

export const Help = () => {
  const { t } = useTranslation("help");

  return (
    <>
      <Typography.Title level={2}>{t("title")}</Typography.Title>
      <ol>
        <li>{t("step_1")}</li>
        <li>{t("step_2")}</li>
        <li>{t("step_3")}</li>
        <li>{t("step_4")}</li>
        <li>{t("step_5")}</li>
        <li>{t("step_6")}</li>
        <li>
          {t("step_7")}
          <ul>
            <li>{t("step_7_1")}</li>
            <li>
              <Trans t={t} i18nKey="help:step_7_2">
                <a target="_blank" rel="noopener noreferrer" href="https://t.me/ltesocks_control_bot">@ltesocks_control_bot</a>
              </Trans>
            </li>
          </ul>
        </li>
      </ol>
      <Typography.Text>
        <Trans t={t} i18nKey="help:footer">
          <a target="_blank" rel="noopener noreferrer" href="https://t.me/ltesocks_support_bot">@ltesocks_support_bot</a>
        </Trans>
      </Typography.Text>
    </>
  );
};
