import { BehaviorSubject } from "rxjs";

interface User {
  login: string
  token: string
}

const baseUrl = process.env.REACT_APP_BASE_URL || "";
const currentUserStorage = localStorage.getItem("currentUser");
const currentUserSubject = new BehaviorSubject(currentUserStorage !== null ? JSON.parse(currentUserStorage) as User : null);

const handleResponse = async (response: Response) => {
  const text = await response.text();
  if (!response.ok) {
    if ([401, 403].indexOf(response.status) !== -1) {
      authenticationService.logout();
    }

    return Promise.reject(response.statusText);
  }

  return text && JSON.parse(text);
};

const login = async (login: string, password: string) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ login, password })
  };

  const response = await fetch(`${baseUrl}/web/user/login`, requestOptions);
  const user = await handleResponse(response);

  localStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);

  return user;
};

const logout = () => {
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
};

const captcha = async () => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  const response = await fetch(`${baseUrl}/web/user/captcha`, requestOptions);
  const text = await response.text();
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }

  return text && JSON.parse(text);
};

const register = async (id: string, email: string, login: string, password: string, captcha: string, promo: string, referrer: string) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ id, email, login, password, captcha, promo, referrer })
  };

  const response = await fetch(`${baseUrl}/web/user/register`, requestOptions);
  const text = await response.text();
  if (!response.ok) {
    return Promise.reject(text && JSON.parse(text));
  }

  return text && JSON.parse(text);
};

const activate = async (token: string) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token })
  };

  const response = await fetch(`${baseUrl}/web/user/activate`, requestOptions);
  const text = await response.text();
  if (!response.ok) {
    return Promise.reject(text && JSON.parse(text));
  }

  return text && JSON.parse(text);
};

const view = async (promo: string, referrer: string) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ promo, referrer })
  };

  const response = await fetch(`${baseUrl}/web/user/view`, requestOptions);
  const text = await response.text();
  if (!response.ok) {
    return Promise.reject(text && JSON.parse(text));
  }

  return text && JSON.parse(text);
};

export const authenticationService = {
  login,
  logout,
  captcha,
  register,
  activate,
  view,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value; }
};

