import { Fragment } from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { Crisp } from "./Crisp";


interface PublicRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const PublicRoute = (props: PublicRouteProps) => {
  const { component: Component, ...rest } = props;

  return (
    <Route {...rest} render={props => {
      return (
        <Fragment>
          <Component {...props} />
          <Crisp
            safeMode={true}
            configuration={{}}
            attributes={{}}
          />
        </Fragment>
      );
    }} />
  );
};
