/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BannerLanguage {
  en = "en",
  ru = "ru",
}

export enum LogSource {
  adminDashboard = "adminDashboard",
  system = "system",
  userApi = "userApi",
  userDashboard = "userDashboard",
}

export enum PaymentHistoryRecordStatus {
  failed = "failed",
  success = "success",
}

export enum PortCredentialsKind {
  ip = "ip",
  password = "password",
}

export enum PortStatus {
  active = "active",
  disconnected = "disconnected",
  outoftraffic = "outoftraffic",
  reset = "reset",
  suspended = "suspended",
}

export interface PaymentHistoryInput {
  from: any;
  to: any;
  page: number;
  pageSize: number;
}

export interface PlanTarificationInput {
  time: number;
  traffic: number;
  price: number;
}

export interface PortCredentialsInput {
  kind: PortCredentialsKind;
  ipFilter?: string | null;
  login?: string | null;
  digest?: string | null;
  password?: string | null;
  speedLimit: number;
}

export interface PortPlanInput {
  plan: string;
  tarification: PlanTarificationInput;
}

export interface SearchInput {
  filter?: string | null;
  sorter?: string | null;
  page?: number | null;
  pageSize?: number | null;
}

export interface SearchLogInput {
  from: any;
  to: any;
  page: number;
  pageSize: number;
}

export interface UpdateUserInput {
  email?: string | null;
  newPassword?: string | null;
}

export interface UpdateUserPreferencesInput {
  vpnServer: string;
  proxyServer: string;
  proxyProtocol: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
