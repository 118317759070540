import { green, grey, red, yellow } from "@ant-design/colors";
import { gql, useQuery } from "@apollo/client";
import { Badge, Space, Spin, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as gt from "../../__generated__/globalTypes";
import * as gq from "./__generated__/Status";

const PORT_STATUS_QUERY = gql`
  query Status($id: ID!) {
    port(id: $id) {
      id
      status
    }
  }
`;

const paletteIndex = 6;
const statusColors: { [key in gt.PortStatus]: string } = {
  [gt.PortStatus.active]: green[paletteIndex],
  [gt.PortStatus.reset]: yellow[paletteIndex],
  [gt.PortStatus.suspended]: red[paletteIndex],
  [gt.PortStatus.disconnected]: grey[paletteIndex],
  [gt.PortStatus.outoftraffic]: grey[paletteIndex],
};

interface StatusProps {
  id: string;
  showText?: boolean;
  showLink?: boolean;
  useTooltip?: boolean;
}

export const Status = (props: StatusProps) => {
  const { t } = useTranslation("port");
  const { loading, data } = useQuery<gq.Status, gq.StatusVariables>(PORT_STATUS_QUERY, {
    variables: { id: props.id }
  });

  if (loading || !data) {
    return <Spin />;
  }

  const text = (
    <Space>
      {props.showLink && <Link to={`/ports/${data.port.id}`}>{data.port.id}</Link>}
      {props.showLink && props.showText && "—"}
      {props.showText && t(data.port.status)}
    </Space>
  );

  const badge = (
    <Badge
      text={text}
      color={statusColors[data.port.status]}
    />
  );

  if (props.useTooltip) {
    return (
      <Tooltip title={t(data.port.status)}>
        {badge}
      </Tooltip>
    );
  }

  return badge;
};
