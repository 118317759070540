import { Col, Input, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "../components/Port/styles.module.less";
import { SearchInput } from "../hooks/search";
type objectWithId = object & { id: string }

interface ListContainerProps<T extends objectWithId> {
  create: React.ReactNode;
  search: SearchInput;
  columns: ColumnsType<T>;
  data?: T[];
  total?: number;
  loading: boolean;
  onSearch: (text: string) => void;
  onTableChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => void;
}

export const ListContainer = <T extends objectWithId,>(props: ListContainerProps<T>) => {
  const { t } = useTranslation("port");

  return (
    <Fragment>
      {props.create}
      <br />
      <br />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Input.Search
            allowClear={true}
            defaultValue={props.search.filter}
            placeholder={t("Search...")}
            onSearch={props.onSearch}
          />
        </Col>
      </Row>


      <br />
      <Table
        className={styles.port_table}
        size="middle"
        scroll={{ x: "max-content" }}
        rowKey={record => record.id}
        loading={props.loading}
        columns={props.columns}
        dataSource={props.data}
        pagination={{
          showSizeChanger: true,
          current: props.search.page,
          pageSize: props.search.pageSize,
          total: props.total,
        }}
        onChange={props.onTableChange}
      />
    </Fragment>
  );
};
