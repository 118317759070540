import { DollarOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Alert, Button, Card, InputNumber, Radio, Space, Spin, Tabs, Typography, notification } from "antd";
import { ReactNode, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMutation, useQuery } from "../../hooks";
import useWidth from "../../hooks/useWidth";
import * as gr from "./__generated__/RequestPayment";
import * as g from "./__generated__/UserPayments";

const GET_PAYMENTS = gql`
  query UserPayments {
    user {
      id
      allowedGateways
    }
  }
`;

const REQUEST_PAYMENT = gql`
  mutation RequestPayment($amount: String!, $gateway: String!, $currency: String!) {
    requestPayment(amount: $amount, gateway: $gateway, currency: $currency) {
      url
      error
    }
  }
`;

const defaultTab = (gws: string[]) => {
  if (gws.includes("wfp")) {
    return "wfp";
  }

  if (gws.includes("anymoney")) {
    return "anymoney";
  }

  if (gws.includes("binance")) {
    return "binance";
  }

  if (gws.includes("whitepay")) {
    return "whitepay";
  }

  if (gws.includes("stripe")) {
    return "stripe";
  }

  if (gws.includes("ipay")) {
    return "ipay";
  }

  if (gws.includes("pspark")) {
    return "pspark";
  }

  return "other";
};

export const Payment = () => {
  const { t } = useTranslation("billing");
  const { data, loading } = useQuery<g.UserPayments, {}>(GET_PAYMENTS);
  const windowSize = useWidth();

  if (loading || !data) {
    return <Spin />;
  }

  return (
    <Card bordered={false} bodyStyle={{ paddingLeft: 0 }}>
      <Tabs defaultActiveKey={defaultTab(data.user.allowedGateways)} tabPosition={windowSize < 576 ? "top" : "left"}>
        {data.user.allowedGateways.includes("wfp") && <Tabs.TabPane key="wfp" tab={t("Credit Card")} >
          <Space size="large" direction="vertical">
            <Typography.Title level={4}>
              {t("Pay with credit card")}
            </Typography.Title>

            <AmountSelect gateway="wfp" showCurrency={false} />
          </Space>
        </Tabs.TabPane>}
        {data.user.allowedGateways.includes("anymoney") && <Tabs.TabPane key="anymoney" tab={<TabTitle currency={t("Cryptocurrency")} gateway={t("AnyMoney")} />} >
          <Space size="large" direction="vertical">
            <Typography.Title level={4}>
              {t("Pay with cryptocurrency")}
            </Typography.Title>

            <Alert
              type="info"
              showIcon={true}
              message={t("Fee Amount")}
              description={t("Any.Money comission")}
            />

            <Alert
              type="info"
              showIcon={true}
              message={t("AMLPolicy")}
              description={
                <Trans t={t} i18nKey="billing:AMLWarning">
                  <a target="_blank" rel="noopener noreferrer" href="https://amlbot.com/">AMLBot</a>
                  <a target="_blank" rel="noopener noreferrer" href="https://t.me/cryptoaml_bot">@cryptoaml_bot</a>
                </Trans>
              }
            />

            <AmountSelect gateway="anymoney" showCurrency={true} />
          </Space>
        </Tabs.TabPane>}
        {data.user.allowedGateways.includes("binance") && <Tabs.TabPane key="binance" tab={<TabTitle currency={t("Cryptocurrency")} gateway={t("Binance Pay")} />} >
          <Space size="large" direction="vertical">
            <Typography.Title level={4}>
              {t("Pay with Binance Pay")}
            </Typography.Title>

            <AmountSelect gateway="binance" showCurrency={false} />
          </Space>
        </Tabs.TabPane>}
        {data.user.allowedGateways.includes("whitepay") && <Tabs.TabPane key="whitepay" tab={<TabTitle currency={t("Cryptocurrency")} gateway={t("Whitepay")} />} >
          <Space size="large" direction="vertical">
            <Typography.Title level={4}>
              {t("Pay with any cryptocurrency via Whitepay")}
            </Typography.Title>

            <Alert
              type="info"
              showIcon={true}
              message={t("Fee Amount")}
              description={t("Whitepay comission")}
            />

            <Alert
              type="info"
              showIcon={true}
              message={t("AMLPolicy")}
              description={
                <Trans t={t} i18nKey="billing:AMLWarning">
                  <a target="_blank" rel="noopener noreferrer" href="https://amlbot.com/">AMLBot</a>
                  <a target="_blank" rel="noopener noreferrer" href="https://t.me/cryptoaml_bot">@cryptoaml_bot</a>
                </Trans>
              }
            />

            <AmountSelect gateway="whitepay" showCurrency={false} />
          </Space>
        </Tabs.TabPane>}
        <Tabs.TabPane key="stripe" tab={<TabTitle currency={t("Various Options")} gateway={t("Stripe")} />} >
          <Space size="large" direction="vertical">
            <Typography.Title level={4}>
              {t("Pay via Stripe")}
            </Typography.Title>

            {data.user.allowedGateways.includes("stripe") && <>
              <Alert
                type="info"
                showIcon={true}
                message={t("Fee Amount")}
                description={
                  <Trans t={t} i18nKey="billing:stripecomission">
                    <a target="_blank" rel="noopener noreferrer" href="https://stripe.com/">Stripe</a>
                  </Trans>
                }
              />

              <AmountSelect gateway="stripe" showCurrency={false} />
            </>}

            {!data.user.allowedGateways.includes("stripe") && <Alert
              type="info"
              showIcon={true}
              message={t("Verification Required")}
              description={t("Stripe verification")}
            />}
          </Space>
        </Tabs.TabPane>
        {data.user.allowedGateways.includes("ipay") && <Tabs.TabPane key="ipay" tab={<TabTitle currency={t("Visa/Master, Apple Pay, Google Pay")} gateway={t("iPay")} />} >
          <Space size="large" direction="vertical">
            <Typography.Title level={4}>
              {t("Pay via iPay")}
            </Typography.Title>

            <AmountSelect gateway="ipay" showCurrency={false} />
          </Space>
        </Tabs.TabPane>}
        {data.user.allowedGateways.includes("pspark") && <Tabs.TabPane key="pspark" tab={<TabTitle currency={t("Cryptocurrency")} gateway={t("PSPark")} />} >
          <Space size="large" direction="vertical">
            <Typography.Title level={4}>
              {t("Pay via PSPark")}
            </Typography.Title>

            <AmountSelect gateway="pspark" showCurrency={true} />
          </Space>
        </Tabs.TabPane>}
        <Tabs.TabPane key="other" tab={t("Other Methods")}>
          <Trans t={t} i18nKey="billing:other">
            <a target="_blank" rel="noopener noreferrer" href="https://t.me/ltesocks">@ltesocks</a>
          </Trans>
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

const TabTitle = (props: { currency: ReactNode, gateway: ReactNode }) => {
  return (
    <div style={{ textAlign: "left" }}>
      <span>{props.currency}</span>
      <br />
      <span style={{ fontSize: "12px" }}>({props.gateway})</span>
    </div>
  );
};

const AmountSelect = (props: { gateway: string, showCurrency: boolean }) => {
  const { t } = useTranslation("billing");
  const [amount, setAmount] = useState("25");
  const [radioAmount, setRadioAmount] = useState("25");
  const [currency, setCurrency] = useState("btc");

  const [requestPayment, { loading }] = useMutation<gr.RequestPayment, gr.RequestPaymentVariables>(REQUEST_PAYMENT, {
    okText: "",
    onCompleted: (data) => {
      if (data.requestPayment.error) {
        notification.error({ message: t(data.requestPayment.error) });
      } else {
        window.open(data.requestPayment.url!, "_blank");
      }
    }
  });

  const pay = () => {
    switch (props.gateway) {
      case "wfp":
        requestPayment({ variables: { amount, currency: "usd", gateway: "wfp" } });
        return;
      case "anymoney":
        requestPayment({ variables: { amount, currency, gateway: "anymoney" } });
        return;
      case "binance":
        requestPayment({ variables: { amount, currency: "busd", gateway: "binance" } });
        return;
      case "whitepay":
        requestPayment({ variables: { amount, currency: "usdt", gateway: "whitepay" } });
        return;
      case "stripe":
        requestPayment({ variables: { amount, currency: "usd", gateway: "stripe" } });
        return;
      case "ipay":
        requestPayment({ variables: { amount, currency: "uah", gateway: "ipay" } });
        return;
      case "pspark":
        requestPayment({ variables: { amount, currency, gateway: "pspark" } });
        return;
    }
  };

  const anymoneySelector = (
    <>
      <Typography.Title level={5}>
        {t("Choose currency")}
      </Typography.Title>

      <Radio.Group
        size="large"
        buttonStyle="solid"
        value={currency}
        onChange={(e) => setCurrency(e.target.value)}
      >
        <Radio.Button value="btc">BTC</Radio.Button>
        <Radio.Button value="eth">ETH</Radio.Button>
        <Radio.Button value="erc20">USDT ERC20</Radio.Button>
        <Radio.Button value="trc20">USDT TRC20</Radio.Button>
      </Radio.Group>
      <br />
    </>
  );

  const psparkSelector = (
    <>
      <Typography.Title level={5}>
        {t("Choose currency")}
      </Typography.Title>

      <Radio.Group
        size="large"
        buttonStyle="solid"
        value={currency}
        onChange={(e) => setCurrency(e.target.value)}
      >
        <Radio.Button value="btc">BTC</Radio.Button>
        <Radio.Button value="eth">ETH</Radio.Button>
        <Radio.Button value="ltc">LTC</Radio.Button>
        <Radio.Button value="usdt">USDT TRC20</Radio.Button>
      </Radio.Group>
      <br />
    </>
  );

  const currencySelector = props.gateway === "anymoney" ? anymoneySelector : props.gateway === "pspark" ? psparkSelector : null;

  return (
    <Space direction="vertical">
      {props.showCurrency && currencySelector}

      <Typography.Title level={5}>
        {t("Choose payment amount")}
      </Typography.Title>

      <Radio.Group
        size="large"
        buttonStyle="solid"
        value={radioAmount}
        onChange={(e) => { setAmount(e.target.value); setRadioAmount(e.target.value); }}
      >
        <Radio.Button value="25">$25</Radio.Button>
        <Radio.Button value="50">$50</Radio.Button>
        <Radio.Button value="100">$100</Radio.Button>
        <Radio.Button value="250">$250</Radio.Button>
        <Radio.Button value="other">{t("Other")}</Radio.Button>
      </Radio.Group>
      {radioAmount === "other" && <InputNumber
        style={{ width: "100%" }}
        min={1}
        max={5000}
        controls={false}
        size="large"
        prefix={<DollarOutlined />}
        placeholder={t("Enter amount")}
        onChange={(v) => setAmount(String(v))}
      />}

      <Button
        size="large"
        type="primary"
        block={true}
        loading={loading}
        onClick={() => pay()}
      >
        {t("Pay")}
      </Button>
    </Space >
  );
};
