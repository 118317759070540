export const downloadConfig = (id: string, server: string, login: string, hexStr: string) => {
  const buf = new ArrayBuffer(hexStr.length / 2);
  const byteBuf = new Uint8Array(buf);
  for (let i = 0; i < hexStr.length; i += 2) {
    byteBuf[i / 2] = parseInt(hexStr.slice(i, i + 2), 16);
  }

  const blob = new Blob([byteBuf], { type: "application/zip" });
  const url = window.URL.createObjectURL(blob);

  var filename = `${server}-${id}.zip`;
  if (login === "arbid1") {
    filename = `arb_vpn_${id}.zip`;
  }

  const tempLink = document.createElement("a");
  tempLink.href = url;
  tempLink.setAttribute("download", filename);
  tempLink.click();
};
