import dayjs from "dayjs";

export const formatBytes = (bytes: number) => {
  if (bytes === 0) return "0 B";

  const k = 1024;
  const dm = 2;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const formatBandwidth = (bytes: number) => {
  if (bytes === 0) return "0 bit/s";
  bytes = bytes * 8;

  const k = 1024;
  const dm = 2;
  const sizes = ["bit/s", "kbit/s", "mbit/s", "gbit/s"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const formatCurrency = (amount: number) => {
  return amount.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

export const formatDate = (date: Date, format?: string) => {
  if (!format) {
    format = "lll";
  }

  return dayjs(date).format(format);
};
