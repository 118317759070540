import { DownloadOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Button, Col, Radio, Row, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { Fragment, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatCurrency, formatDate } from "../../common/formatter";
import { useQuery } from "../../hooks";
import useWidth from "../../hooks/useWidth";
import DatePicker from "../DatePicker";
import * as g from "./__generated__/PaymentHistory";

const { RangePicker } = DatePicker;

const PAYMENT_HISTORY = gql`
  query PaymentHistory($input: PaymentHistoryInput!) {
    paymentHistory(input: $input) {
      data {
        status
        amount
        balance
        date
        port
        description
      }
      page
      pageSize
      total
    }
  }
`;

const renderStatus = (t: TFunction, status: string) => {
  if (status === "success") {
    return <Tag color="success">{t("success")}</Tag>;
  }

  return <Tag color="error">{t("failed")}</Tag>;
};

const renderAmount = (amount: number) => {
  return (
    <Typography.Text
      type={(amount < 0) ? "danger" : "success"}
    >
      {formatCurrency(amount / 100)}
    </Typography.Text>
  );
};

const plainColumns = (t: TFunction): ColumnsType<g.PaymentHistory_paymentHistory_data> => [
  {
    key: "status",
    title: t("Status"),
    render: (record) => renderStatus(t, record.status),
    
  }, 
  {
    dataIndex: "description",
    title: t("Description"),
    responsive: ["md"],
  },
  {
    key: "date",
    title: t("Date"),
    render: (record) => formatDate(record.date),
  },
  {
    key: "amount",
    title: t("Amount"),
    align: "right",
    render: (record) => renderAmount(record.amount),
  },
  {
    key: "balance",
    title: t("Balance"),
    align: "right",
    render: (record) => formatCurrency(record.balance / 100),
  },
];

const portColumns = (t: TFunction, allPorts: string[]): ColumnsType<groupRecord> => [
  {
    key: "port",
    title: t("Port"),
    filters: allPorts.map(x => { return { text: x, value: x }; }),
    filterSearch: true,
    onFilter: (value, record) => record.port.startsWith(value as string),
    render: (record) => record.port === "Other" ? record.port : <Link to={`/ports/${record.port}`}>{record.port}</Link>,
  },
  {
    key: "date",
    title: t("Date"),
    render: (record) => formatDate(record.date),
  },
  {
    key: "total",
    title: t("Total"),
    align: "right",
    render: (record) => renderAmount(record.total),
  }
];

interface HistoryState {
  from: Date
  to: Date
  page: number
  pageSize: number
  grouping: "plain" | "port"
}

const initialState: HistoryState = {
  from: dayjs().startOf("month").toDate(),
  to: dayjs().endOf("month").toDate(),
  page: 1,
  pageSize: 20,
  grouping: "plain",
};

const historyReducer = (
  state: HistoryState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "DATES":
      return { ...state, from: action.data[0], to: action.data[1] };
    case "PAGINATE":
      return { ...state, page: action.data.page, pageSize: action.data.pageSize };
    case "GROUP":
      return {
        ...state,
        grouping: action.data,
        page: 1,
        pageSize: action.data === "port" ? 0 : 20,
      };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

const renderCSV = (data: g.PaymentHistory_paymentHistory_data[]) => {
  const items = data.map(x => {
    return {
      status: x.status,
      amount: (x.amount / 100).toFixed(2),
      balance: (x.balance / 100).toFixed(2),
      date: x.date,
      port: x.port,
      description: x.description,
    };
  });

  const replacer = (_: any, value: any) => value === null ? "" : value;
  const header = Object.keys(items[0]);

  return [
    header.join(","),
    ...items.map((row: any) => header.map(fname => JSON.stringify(row[fname], replacer)).join(","))
  ].join("\r\n");
};

interface groupHash {
  [port: string]: groupRecord
}

interface groupRecord {
  port: string
  date: string
  total: number
  records: g.PaymentHistory_paymentHistory_data[]
}

const chargedAmount = (record: g.PaymentHistory_paymentHistory_data) => record.status === "success" ? record.amount : 0;
const groupRecordsByPort = (date: string, records?: g.PaymentHistory_paymentHistory_data[]) => {
  const grouped = (records || []).reduce((acc, x) => {
    if (!acc[x.port]) {
      acc[x.port] = {
        port: x.port,
        date: date,
        total: chargedAmount(x),
        records: [x],
      };

      return acc;
    }

    acc[x.port].total += chargedAmount(x);
    acc[x.port].records.push(x);

    return acc;
  }, {} as groupHash);

  return Object.keys(grouped).map(x => grouped[x]).sort((a, b) => a.port.localeCompare(b.port)).reverse();
};


export const History = () => {
  const { t } = useTranslation("billing");
  const [state, dispatch] = useReducer(historyReducer, initialState);
  const width = useWidth();
  const { loading, data, refetch } = useQuery<g.PaymentHistory, {}>(PAYMENT_HISTORY, {
    variables: {
      input: {
        from: state.from,
        to: state.to,
        page: state.page,
        pageSize: state.pageSize
      }
    }
  });
  

  return (
    <Fragment>
      <Row gutter={24} style={{ paddingTop: "24px" }}>
        <Col>
          <RangePicker
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[dayjs(state.from), dayjs(state.to)]}
            onChange={(dates) => dispatch({ type: "DATES", data: dates })}
          />
        </Col>
        <Col>
          <Radio.Group
            buttonStyle="solid"
            value={state.grouping}
            onChange={(val) => dispatch({ type: "GROUP", data: val.target.value })}
          >
            <Radio.Button value="plain">{t("By order")}</Radio.Button>
            <Radio.Button value="port">{t("By ports")}</Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Button
            icon={<DownloadOutlined />}
            onClick={() => {
              refetch({
                input: {
                  from: state.from,
                  to: state.to,
                  page: 1,
                  pageSize: 0,
                }
              }).then(result => {
                const csv = renderCSV(result.data.paymentHistory.data || []);
                const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
                const url = window.URL.createObjectURL(blob);

                const filename = "payments.csv";
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", filename);
                tempLink.click();
              });
            }}
          >
            {t("Download CSV")}
          </Button>
        </Col>
      </Row>
      <br />
      {state.grouping === "plain" && <Table
        size="middle"
        style={{overflowX: "scroll"}}
        rowKey={record => record.date}
        loading={loading}
        columns={plainColumns(t)}
        dataSource={data?.paymentHistory.data}
        pagination={{
          showSizeChanger: true,
          current: data?.paymentHistory.page,
          pageSize: data?.paymentHistory.pageSize,
          total: data?.paymentHistory.total,
        }}
        expandable={width < 768 ? {
          expandedRowRender: record => <p style={{margin: 0, wordBreak: "break-word"}}>{record.description}</p>
        } : {} }
        onChange={(pagination, _filters, _sorter, _extra) => dispatch({ type: "PAGINATE", data: { page: pagination.current, pageSize: pagination.pageSize } })}
      />}
      {state.grouping === "port" && <Table
        size="middle"
        rowKey={record => record.port}
        loading={loading}
        columns={portColumns(t, data?.paymentHistory.data.map(x => x.port).filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => a.localeCompare(b)).reverse() || [])}
        pagination={{
          defaultPageSize: 20
        }}
        expandedRowRender={row => <Table
          size="middle"
          rowKey={record => record.date}
          columns={plainColumns(t)}
          pagination={false}
          dataSource={row.records}
        />}
        dataSource={groupRecordsByPort(state.to, data?.paymentHistory.data)}
      />}
    </Fragment>
  );
};
