import { Col, Row, Tabs, Typography } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Balance, History, Payment } from "./";

export const Billing = () => {
  const { t } = useTranslation("billing");

  return (
    <Fragment>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={2}>{t("Billing")}</Typography.Title>
        </Col>

        <Col>
          <Balance />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="payment">
            <Tabs.TabPane tab={t("Top Up Account")} key="payment">
              <Payment />
            </Tabs.TabPane>

            <Tabs.TabPane tab={t("History")} key="history">
              <History />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Fragment>
  );
};
