import { Col, Layout, Row } from "antd";
import React from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { authenticationService } from "../common/auth";
import { Banner } from "../components/Banner";
import { Breadcrumb } from "../components/Breadcrumb";
import { Header } from "../components/Header";
import styles from "./PrivateRoute.module.less"; 
import { Sidebar } from "./Sidebar";
const { Content } = Layout;
interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  return (
    <Route {...rest} render={props => {
      const currentUser = authenticationService.currentUserValue;
      if (!currentUser) {
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
      }
      return (
        <Layout style={{ minHeight: "100vh" }}>
          <Sidebar />
          <Layout style={{ background: "#ffffff" }}>
            <Header />
            <Content className={styles.content}>
              <Row className={styles.headrow}>
                <Col span={10}><Breadcrumb /></Col>
                <Col span={14} className={styles.banner} ><Banner /></Col>
              </Row>
              <div style={{ background: "#ffffff", minHeight: 360, marginTop: "12px" }}>
                <Component {...props} />
              </div>
            </Content>
          </Layout>
        </Layout >
      );
    }} />
  );
};
