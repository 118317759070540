import { gql } from "@apollo/client";
import { Button, Space, Statistic, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "../../hooks";
import * as g from "./__generated__/UserLimits";
import * as gu from "./__generated__/RequestLimitsIncrease";

const GET_LIMITS = gql`
  query UserLimits {
    user {
      id
      portsCount
      portsLimit
    }
  }
`;

const REQUEST_LIMITS_INCREASE = gql`
  mutation RequestLimitsIncrease {
    requestLimitsIncrease
  }
`;

const PortLimits = () => {
  const { t } = useTranslation("account");
  const { loading, data } = useQuery<g.UserLimits, {}>(GET_LIMITS);

  if (loading) {
    return <Statistic loading={true} />;
  }

  return (
    <Statistic
      title={t("Ports")}
      value={data!.user.portsCount}
      suffix={`/ ${data!.user.portsLimit === 0 ? "∞" : data!.user.portsLimit}`}
    />
  );
};

export const Limits = () => {
  const { t } = useTranslation("account");

  const [requestLimitsIncrease] = useMutation<gu.RequestLimitsIncrease, {}>(REQUEST_LIMITS_INCREASE, {
    okText:  t("Limit increase requested"),
  });

  return (
    <Space direction="vertical">
      <Typography.Title level={3}>{t("Limits")}</Typography.Title>

      <PortLimits />

      <Button
        type="primary"
        style={{ marginTop: 16 }}
        onClick={() => requestLimitsIncrease()}
      >
        {t("Request Limit Increase")}
      </Button>
    </Space>
  );
};
