import { InputNumber, Select, Typography } from "antd";
import { useState } from "react";

type Unit = "kbps" | "mbps"

const unitMultipliers = {
  "kbps": 1024 / 8,
  "mbps": 1024 * 1024 / 8,
};

// https://github.com/ant-design/ant-design/issues/31431
const unitPrecision = {
  "kbps": 0,
  "mbps": 1,
};


const bandwidthParser = (unit: Unit) => (value: string | undefined) => {
  if (value === "∞") {
    return 0;
  }

  const numValue = parseInt(value || "", 10);
  return Number.isNaN(numValue) ? 0 : unitMultipliers[unit] * numValue;
};

const bandwidthFormatter = (unit: Unit) => (value: number | undefined, info: { userTyping: boolean, input: string }) => {
  if (!value) {
    return "∞";
  }

  if (value <= 0) {
    return "∞";
  }

  return (value / unitMultipliers[unit]).toFixed(0);
};

export interface InputBandwidthProps {
  value?: number;
  onChange?: (value: number) => void;
  onSetInfinite?: () => void;
}

export const InputBandwidth = (props: InputBandwidthProps) => {
  const [unit, setUnit] = useState<Unit>("mbps");

  const infiniteSetter = (
    <Typography.Text
      style={{ cursor: "pointer" }}
      onClick={() => props.onChange && props.onChange(0)}
    >
      ∞
    </Typography.Text>
  );

  const unitSelector = (
    <Select
      value={unit}
      onChange={setUnit}
    >
      <Select.Option value="kbps">kbit/s</Select.Option>
      <Select.Option value="mbps">mbit/s</Select.Option>
    </Select>
  );

  return (
    <InputNumber
      addonAfter={unitSelector}
      addonBefore={infiniteSetter}
      parser={bandwidthParser(unit)}
      formatter={bandwidthFormatter(unit)}
      precision={unitPrecision[unit]}
      step={unitMultipliers[unit]}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
