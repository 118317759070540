import { gql } from "@apollo/client";
import { Card, Col, Row, Spin, Statistic, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { formatCurrency, formatDate } from "../../common/formatter";
import { useMutation, useQuery } from "../../hooks";
import * as g from "./__generated__/PortPlan";
import * as gu from "./__generated__/UpdatePortAutoRenew";

const GET_PORT_PLAN = gql`
  query PortPlan($id: ID!) {
    port(id: $id) {
      id
      plan {
        enabled
        name
        autoRenew
        tarification {
          time
          traffic
          price
        }
        activatedAt
        expiresAt
        trafficRemains
      }
    }
  }
`;

const UPDATE_AUTO_RENEW = gql`
  mutation UpdatePortAutoRenew($id: ID!, $autoRenew: Boolean!) {
    updatePortAutoRenew(id: $id, autoRenew: $autoRenew) {
      id
      plan {
        enabled
        name
        autoRenew
        tarification {
          time
          traffic
          price
        }
        activatedAt
        expiresAt
        trafficRemains
      }
    }
  }
`;

const formatOptTitle = (opt: any) => {
  if (opt.time === undefined) {
    return undefined;
  }

  return `${opt.time / 86400} Days / ${opt.traffic <= 0 ? "∞" : opt.traffic / 1024} GB / ${formatCurrency(opt.price / 100)}`;
};

export const EditPlan = () => {
  const { t } = useTranslation("port");
  const { id } = useParams<{ id: string }>();

  const { loading, data } = useQuery<g.PortPlan, g.PortPlanVariables>(GET_PORT_PLAN, {
    variables: { id }
  });

  const [updateAutoRenew] = useMutation<gu.UpdatePortAutoRenew, gu.UpdatePortAutoRenewVariables>(UPDATE_AUTO_RENEW, {
    okText: t("Port updated"),
    refetchQueries: ["SearchLogPort"]
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Card title={t("Plan")}>
      <Row gutter={24}>
        <Col span={8}>
          <Statistic title={t("Plan")} value={data!.port.plan.name} />
        </Col>
        <Col span={8}>
          <Statistic title={t("Option")} value={formatOptTitle(data!.port.plan.tarification)} />
        </Col>
        <Col span={8}>
          <Statistic title={t("Activated At")} value={formatDate(data!.port.plan.activatedAt)} />
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={8}>
          <Statistic
            title={t("Auto Renew")}
            formatter={(value) => {
              return (
                <Switch
                  defaultChecked={data!.port.plan.autoRenew}
                  onChange={(value) => updateAutoRenew({ variables: { id, autoRenew: value } })}
                />
              );
            }}
          />
        </Col>
        <Col span={8}>
          <Statistic title={t("Traffic Remaining")} value={`${data!.port.plan.trafficRemains <= 0 ? "∞" : (data!.port.plan.trafficRemains / 1024).toFixed(2)} GB`} />
        </Col>
        <Col span={8}>
          <Statistic title={t("Expires At")} value={formatDate(data!.port.plan.expiresAt)} />
        </Col>
      </Row>
    </Card>
  );
};
