import { CopyOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Button, Card, InputNumber, notification, Space, Spin, Switch, Typography } from "antd";
import { Fragment, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "../../hooks";
import { IP, Status } from "./";
import * as g from "./__generated__/PortVPN";
import * as gr from "./__generated__/ResetPortEdit";
import * as ga from "./__generated__/UpdatePortAutoResetInterval";
import * as gv from "./__generated__/VPNConfig";
import { downloadConfig } from "./DownloadConfig";


const GET_PORT_VPN = gql`
  query PortVPN($id: ID!) {
    user {
      id
      login
      allowAutoResetInterval
      preferences {
        vpnServer
        proxyServer
        proxyProtocol
      }
    }
    port(id: $id) {
      id
      vpnAccess
      resetToken
      autoResetInterval
    }
  }
`;

const RESET_PORT = gql`
  mutation ResetPortEdit($id: ID!) {
    resetPort(id: $id) {
      id
      ip
      status
    }
  }
`;

const VPN_CONFIG = gql`
  mutation VPNConfig($port: String!) {
    vpnConfig(port: $port)
  }
`;

const UPDATE_PORT_AUTO_RESET_INTERVAL = gql`
  mutation UpdatePortAutoResetInterval($id: ID!, $interval: Int!) {
    updatePortAutoResetInterval(id: $id, interval: $interval) {
      id
      autoResetInterval
    }
  }
`;

export const EditStatus = () => {
  const { t } = useTranslation("port");
  const { id } = useParams<{ id: string }>();
  const [autoResetEnabled, setAutoResetEnabled] = useState(false);
  const [autoResetInterval, setAutoResetInterval] = useState(0);

  const { loading, data } = useQuery<g.PortVPN, g.PortVPNVariables>(GET_PORT_VPN, {
    variables: { id },
    onCompleted: (data) => {
      setAutoResetEnabled(data.port.autoResetInterval > 0);
      setAutoResetInterval(data.port.autoResetInterval);
    }
  });

  const [fetchConfig] = useMutation<gv.VPNConfig, gv.VPNConfigVariables>(VPN_CONFIG, {
    onCompleted: (z => {
      downloadConfig(id, data?.user.preferences.vpnServer || "", data?.user.login || "", z.vpnConfig);
    })
  });

  const [resetPort] = useMutation<gr.ResetPortEdit, gr.ResetPortEditVariables>(RESET_PORT, {
    okText: t("Reset scheduled"),
    refetchQueries: ["PortVPN", "SearchLogPort"],
  });

  const [updateAutoResetInterval] = useMutation<ga.UpdatePortAutoResetInterval, ga.UpdatePortAutoResetIntervalVariables>(UPDATE_PORT_AUTO_RESET_INTERVAL, {
    okText: t("Port updated"),
    refetchQueries: ["PortVPN", "SearchLogPort"],
  });

  if (loading) {
    return <Spin />;
  }

  return (
    <Fragment>
      <Card title={t("Status")}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Status id={id} showText={true} />
          <IP id={id} />
          <br />
          <Space>
            <Button
              type="primary"
              onClick={() => resetPort({ variables: { id } })}>
              {t("Reset IP")}
            </Button>
            <CopyToClipboard
              text={`https://api.ltesocks.io/v2/port/reset/${data?.port.resetToken}`}
              onCopy={() => notification.info({ message: t("Link copied") })}
            >
              <Button
                icon={<CopyOutlined />}
              >
                {t("Reset Link")}
              </Button>
            </CopyToClipboard>
            {data?.port.vpnAccess && <Button
              onClick={() => fetchConfig({ variables: { port: id } })}
            >
              {t("VPN Config")}
            </Button>}
          </Space>
        </Space>
      </Card >
      <br />
      {data?.user.allowAutoResetInterval && <Card title={t("Auto Reset")}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Space>
            <Switch
              checkedChildren={t("Enabled")}
              unCheckedChildren={t("Disabled")}
              checked={autoResetEnabled}
              onChange={(v) => { setAutoResetEnabled(v); v ? setAutoResetInterval((data?.port.autoResetInterval !== 0 ? data?.port.autoResetInterval : 180) || 0) : setAutoResetInterval(0); }}
            />

            <InputNumber
              addonAfter={t("Min")}
              min={0}
              max={1440}
              disabled={!autoResetEnabled}
              value={autoResetInterval / 60}
              onChange={(v) => setAutoResetInterval(v * 60)}
            />
          </Space>

          <Typography.Text type="secondary">{t("Port will be reset automatically each time after the selected interval has elapsed")}</Typography.Text>

          <Button
            type="primary"
            style={{ margin: "8px 0" }}
            onClick={() => updateAutoResetInterval({ variables: { id, interval: autoResetEnabled ? autoResetInterval : 0 } })}
          >
            {t("Update")}
          </Button>
        </Space>
      </Card>}
    </Fragment>
  );
};
