import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Button, Card, Col, Form, Input, Modal, Row, Select, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Fragment, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as gg from "../../__generated__/globalTypes";
import { formatBandwidth } from "../../common/formatter";
import { useMutation, useQuery } from "../../hooks";
import { downloadConfig } from "./DownloadConfig";
import { InputBandwidth } from "./InputBandwidth";
import * as g from "./__generated__/PortCredentials";
import * as gu from "./__generated__/UpdatePortCredentials";
import * as gc from "./__generated__/VPNCredentialsConfig";

const GET_PORT_CREDENTIALS = gql`
  query PortCredentials($id: ID!) {
    user {
      id
      login
      group
      preferences {
        vpnServer
        proxyServer
        proxyProtocol
      }
    }
    port(id: $id) {
      id
      credentials {
        kind
        ipFilter
        login
        digest
        speedLimit
      }
    }
  }
`;


const UPDATE_PORT_CREDENTIALS = gql`
  mutation UpdatePortCredentials($id: ID!, $input: [PortCredentialsInput!]!) {
    updatePortCredentials(id: $id, input: $input) {
      id
      credentials {
        kind
        ipFilter
        login
        digest
        speedLimit
      }
    }
  }
`;

const VPN_CONFIG = gql`
  mutation VPNCredentialsConfig($port: String!, $login: String!, $passwordDigest: String!) {
    vpnCredentialsConfig(port: $port, login: $login, passwordDigest: $passwordDigest)
  }
`;

interface IPortCredentialsState {
  value: g.PortCredentials_port_credentials[];
  isOpen: boolean;
}

const initialState: IPortCredentialsState = {
  value: [],
  isOpen: false,
};

const portCredentialsReducer = (
  state: IPortCredentialsState,
  action: { type: string, data: any },
) => {
  switch (action.type) {
    case "INIT":
      return { ...state, value: action.data.credentials };
    case "ADD":
      return { ...state, isOpen: !state.isOpen, value: [...state.value, action.data] };
    case "TOGGLE":
      return { ...state, isOpen: !state.isOpen };
    case "DELETE_IP":
      return { ...state, value: state.value.filter(x => x.ipFilter !== action.data) };
    case "DELETE_PASSWORD":
      return { ...state, value: state.value.filter(x => x.digest !== action.data) };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const EditCredentials = () => {
  const { t } = useTranslation("port");
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(portCredentialsReducer, initialState);
  const [addForm] = Form.useForm();

  const { data, loading } = useQuery<g.PortCredentials, g.PortCredentialsVariables>(GET_PORT_CREDENTIALS, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.port });
    }
  });

  const [fetchConfig] = useMutation<gc.VPNCredentialsConfig, gc.VPNCredentialsConfigVariables>(VPN_CONFIG, {
    onCompleted: (z) => {
      downloadConfig(id, data?.user.preferences.vpnServer || "", data?.user.login || "", z.vpnCredentialsConfig);
    }
  });

  const [updateCredentials] = useMutation<gu.UpdatePortCredentials, gu.UpdatePortCredentialsVariables>(UPDATE_PORT_CREDENTIALS, {
    okText: t("Port updated"),
    refetchQueries: ["SearchLogPort"]
  });


  const ipColumns: ColumnsType<g.PortCredentials_port_credentials> = [
    {
      key: "ipFilter",
      title: t("IP Filter"),
      dataIndex: "ipFilter",
    },
    {
      key: "speedLimit",
      title: t("Speed Limit"),
      render: (_text, record) => record.speedLimit === 0 ? "∞" : formatBandwidth(record.speedLimit),
    },
    {
      key: "delete",
      title: t("Delete"),
      width: 100,
      render: (_text, record) => <Button onClick={() => dispatch({ type: "DELETE_IP", data: record.ipFilter })}>{t("Delete")}</Button>
    },
  ];

  const passwordColumns: ColumnsType<g.PortCredentials_port_credentials> = [
    {
      key: "login",
      title: t("Login"),
      dataIndex: "login",
    },
    {
      key: "speedLimit",
      title: t("Speed Limit"),
      render: (_text, record) => record.speedLimit === 0 ? "∞" : formatBandwidth(record.speedLimit),
    },
    {
      key: "delete",
      title: t("Delete"),
      width: 100,
      render: (_text, record) => <Button onClick={() => dispatch({ type: "DELETE_PASSWORD", data: record.digest })}>{t("Delete")}</Button>
    },
  ];

  const vpnConfigColumns: ColumnsType<g.PortCredentials_port_credentials> = [
    {
      key: "vpnConfig",
      title: t("VPN Config"),
      render: (_text, record) => (
        <Button
          icon={<DownloadOutlined />}
          onClick={() => { fetchConfig({ variables: { port: id, login: record.login!, passwordDigest: record.digest! } }); }}
        />
      ),
    }
  ];

  const newDialogFooter = (
    <Space>
      <Button onClick={() => dispatch({ type: "TOGGLE", data: {} })}>
        {t("Cancel")}
      </Button>
      <Button type="primary" onClick={() => addForm.submit()}>
        {t("Create")}
      </Button>
    </Space>
  );


  if (loading) {
    return <Spin />;
  }

  if (data?.user.group === "partner") {
    passwordColumns.splice(2, 0, ...vpnConfigColumns);
  }

  return (
    <Card title={t("Credentials")}>
      <Modal
        title={t("New credential")}
        visible={state.isOpen}
        centered={true}
        footer={newDialogFooter}
        forceRender={true}
        onCancel={() => dispatch({ type: "TOGGLE", data: {} })}
      >
        <Form
          {...formLayout}
          form={addForm}
          name="add-credentials"
          initialValues={{ kind: "ip", speedLimit: 0 }}
          onFinish={(values) => dispatch({ type: "ADD", data: { ...values, digest: `${Date.now()}` } })}
        >
          <Form.Item name="kind" label={t("Kind")} rules={[{ required: true }]}>
            <Select style={{ width: 120 }}>
              <Select.Option value="ip">{t("IP")}</Select.Option>
              <Select.Option value="password">{t("Password")}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              if (getFieldValue("kind") === "ip") {
                return <Form.Item name="ipFilter" label={t("IP Filter")} rules={[{ required: true, pattern: /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm, message: t("IP Filter does not contain valid IP address") }]}>
                  <Input />
                </Form.Item>;
              }

              if (getFieldValue("kind") === "password") {
                return (
                  <Fragment>
                    <Form.Item name="login" label={t("Login")} rules={[{ required: true, min: 2, max: 100 }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name="password" label={t("Password")} rules={[{ required: true, min: 5, max: 100 }]}>
                      <Input />
                    </Form.Item>
                  </Fragment>
                );
              }
            }}
          </Form.Item>
          <Form.Item name="speedLimit" label={t("Speed Limit")} rules={[{ required: true }]}>
            <InputBandwidth />
          </Form.Item>
        </Form>
      </Modal>

      <Space style={{ width: "100%" }} direction="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <Table
              size="small"
              rowKey={x => `ip-${x.digest}`}
              columns={passwordColumns}
              dataSource={state.value.filter((x: any) => x.kind === gg.PortCredentialsKind.password)}
              pagination={false}
            />
          </Col>
          <Col span={12}>
            <Table
              size="small"
              rowKey={x => `ip-${x.ipFilter}`}
              columns={ipColumns}
              dataSource={state.value.filter((x: any) => x.kind === gg.PortCredentialsKind.ip)}
              pagination={false}
            />
          </Col>
        </Row>

        <Space style={{ margin: "16px 0" }}>
          <Button
            type="primary"
            onClick={() => updateCredentials({ variables: { id, input: state.value } })}
          >
            {t("Update")}
          </Button>

          <Button
            icon={<PlusOutlined />}
            onClick={() => dispatch({ type: "TOGGLE", data: {} })}
          >
            {t("New")}
          </Button>
        </Space>
      </Space>
    </Card >
  );
};
