import { gql } from "@apollo/client";
import { Space, Statistic } from "antd";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../common/formatter";
import { useQuery } from "../../hooks";
import * as g from "./__generated__/UserBalance";

const GET_BALANCE = gql`
  query UserBalance {
    user {
      id
      balance
      discountPercent
    }
  }
`;

export const Balance = () => {
  const { t } = useTranslation("billing");
  const { loading, data } = useQuery<g.UserBalance, {}>(GET_BALANCE);

  if (loading) {
    return <Statistic loading={true} />;
  }

  return (
    <Space size={[32, 0]}>
      {data!.user.discountPercent > 0 && <Statistic
        title={t("Discount")}
        value={`${data!.user.discountPercent}%`}
      />}
      <Statistic
        title={t("Remaining Credit")}
        value={formatCurrency(data!.user.balance / 100)}
      />
    </Space>
  );
};
