import { Button, Col, Form, Input, notification, Result, Row, Spin } from "antd";
import { ResultStatusType } from "antd/lib/result";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { authenticationService } from "../../common/auth";
import styles from "./styles.module.less";


const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};
const tailFormItemLayout = {
  wrapperCol: { span: 16, offset: 8 },
};

interface ICaptcha {
  id?: string;
  b64s?: string;
}

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const Register = () => {
  const { t } = useTranslation("register");
  const query = useQuery();
  const history = useHistory();
  const [captcha, setCaptcha] = useState<ICaptcha>({});
  const [promo, referrer] = [query.get("promo") || "", document.referrer];

  const handleCaptcha = () => {
    authenticationService.captcha()
      .then((data: ICaptcha) => setCaptcha(data))
      .catch((text) => notification.error({
        message: t("Error"),
        description: t(text),
      }));
  };

  const handleRegister = (values: any) => {
    authenticationService.register(values.id, values.email, values.login, values.password, values.captcha, promo, referrer)
      .then(() => history.push("/login"))
      .catch((text) => {
        if (text.error === "pending") {
          history.push("/register/pending");
          return;
        }

        if (text.error === "bad captcha") {
          handleCaptcha();
        }

        notification.error({
          message: t("Error"),
          description: t(text.error),
        });
      });
  };

  useEffect(() => {
    handleCaptcha();
    promo !== "" && authenticationService.view(promo, referrer);
  }, []);

  return (
    <Fragment>
      <div className={styles.form}>
        <div className={styles.logo}>
          <img alt="logo" src="/logo192.png" />
          <span>{t("Registration")}</span>
        </div>

        <Form
          {...formItemLayout}
          name="register"
          onFinish={(values) => handleRegister({ ...values, id: captcha.id || "" })}
        >
          <Form.Item
            name="login"
            label={t("Login")}
            rules={[{ required: true, min: 2, max: 100 }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label={t("E-Mail")}
            rules={[{ type: "email" }, { required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label={t("Password")}
            hasFeedback={true}
            rules={[{ required: true, min: 5, max: 100 }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={t("Confirm Password")}
            dependencies={["password"]}
            hasFeedback={true}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("The two passwords that you entered do not match!")));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item label={t("Captcha")} extra={t("If you cannot see CAPTCHA please disable adblock in your browser and refresh page")}>
            {captcha.b64s && <img style={{ marginBottom: "8px" }} alt={t("Captcha")} src={captcha.b64s} />}
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="captcha"
                  noStyle={true}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button onClick={handleCaptcha}>{t("Refresh")}</Button>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              {t("Register")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Fragment >
  );
};

export const Pending = () => {
  const { t } = useTranslation("register");

  return (
    <Fragment>
      <div className={styles.form}>
        <div className={styles.logo}>
          <img alt="logo" src="/logo192.png" />
          <span>{t("Registration")}</span>
        </div>

        <Result
          status="success"
          title={t("Sign up request accepted")}
          subTitle={t("Please check your email to activate your account")}
        />
      </div>
    </Fragment >
  );
};

interface IActivationStatus {
  text: string;
  status: string;
}

export const Activate = () => {
  const { t } = useTranslation("register");
  const { token } = useParams<{ token: string }>();
  const [status, setStatus] = useState<IActivationStatus>({ text: "", status: "loading" });

  const handleActivation = () => {
    authenticationService.activate(token)
      .then(() => setStatus({ text: t("Account confirmed"), status: "success" }))
      .catch(() => setStatus({ text: t("Activation error"), status: "error" }));
  };

  useEffect(() => {
    handleActivation();
  }, []);

  return (
    <Fragment>
      <div className={styles.form}>
        <div className={styles.logo}>
          <img alt="logo" src="/logo192.png" />
          <span>{t("Registration")}</span>
        </div>

        {status.status === "loading" && <Spin />}
        {status.status !== "loading" &&
          <Result
            status={status.status as ResultStatusType}
            title={status.text}
            extra={status.status === "success" && <Link to={"/login"}>
              <Button type="primary" size="large">
                {t("Sign In")}
              </Button>
            </Link>}
          />}
      </div>
    </Fragment >
  );
};
